const getButtonStyles = (color) => {
  if (color?.includes('Blue')) return 'secondary';
  if (color?.includes('Critical')) return 'primary';
  switch (color?.toLowerCase()) {
    case 'blue':
      return 'secondary';
    case 'secondary':
      return 'secondary';
    case 'white':
      return 'white';
    case 'black':
      return 'tertiary';
    case 'tertiary':
      return 'tertiary';
    case 'purple':
      return 'purple';
    case 'transparent - white border':
      return 'transparentWhiteBorder';
    case 'transparent - black border':
      return 'transparentBlackBorder';
    case 'transparent - blue border':
      return 'transparentBlueBorder';
    case 'transparent - black text - no border':
      return 'transparentBlackTextNoBorder';
    case 'transparent - white text - no border':
      return 'transparentWhiteTextNoBorder';
    default:
      return 'primary';
  }
};

export default getButtonStyles;
