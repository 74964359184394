/* eslint-disable import/prefer-default-export */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Icon from '../../Icon';
import { DEFAULT_ICON_MAP } from './defaultSpriteProps';

export const STIcon = ({ loading = 'lazy', type, size, ...otherProps }) => {
  return DEFAULT_ICON_MAP[type] ? (
    <Icon id={type} {...DEFAULT_ICON_MAP[type]} {...otherProps} />
  ) : type === 'icon-pagination-arrow-prev' ? (
    <Icon
      id="icon-pagination-arrow-next"
      width="9"
      height="14"
      {...otherProps}
      style={{ ...otherProps.style, transform: 'rotate(180deg)' }}
    />
  ) : type === 'icon-loading' ? (
    <LoadingIcon id={type} width="14" height="14" fill="#c4c4c4" {...otherProps} />
  ) : (
    <IconWrapper
      className={`st-icon-wrapper ${
        (otherProps.className !== undefined ? otherProps.className : '').default
      }`}
      size={size}
      {...otherProps}
    >
      <Icon id={`${type}-${size}`} {...otherProps} />
    </IconWrapper>
  );
};

STIcon.propTypes = {
  width: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.string, // large, small, dark
};

STIcon.defaultProps = {
  size: 'small',
};

const LoadingIcon = styled(Icon)`
  animation: loadingCircle 1s infinite linear;
  @keyframes loadingCircle {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const IconWrapper = styled.div`
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.size === 'large' ? '80px' : '40px')};
  height: ${(props) => (props.size === 'large' ? '80px' : '40px')};
  min-width: ${(props) => (props.size === 'large' ? '80px' : '40px')};
  border: ${(props) =>
    props.size === 'large'
      ? '3px solid var(--dark)'
      : props.size === 'small'
      ? '2px solid var(--dark)'
      : 'none'};
  svg {
    width: ${(props) => (props.size === 'large' ? '80px' : '40px')};
    height: ${(props) => (props.size === 'large' ? '80px' : '40px')};
    min-width: ${(props) => (props.size === 'large' ? '80px' : '40px')};
  }
`;
