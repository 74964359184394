import styled, { css } from 'styled-components';
import HyperLink from 'components/Core/HyperLink';

const btnStyles = {
  shadow: 'shadow-[0px 2px 10px 0px rgba(0,0,0,0.2)]',
  primary:
    'bg-critical-3 border-none text-white hover:bg-critical-4 hover:text-white hover:border-none',
  secondary: 'bg-titan-blue-3 text-white hover:bg-titan-blue-4 hover:text-white hover:border-none',
  tertiary: 'bg-darkest text-white hover:bg-dark hover:text-white hover:border-none',
  white: 'bg-white text-darkest hover:bg-grey-3 hover:text-darkest hover:border-none',
  transparent: 'text-darkest text-white hover:border-none',
  purple: 'bg-titan-blue-3 text-white hover:bg-titan-blue-3 hover:text-white hover:border-none',
  transparentWhiteBorder:
    'bg-transparent border border-solid border-white text-white px-6 py-[15px] hover:bg-none hover:text-white hover:border-grey-6',
  transparentBlackBorder:
    'bg-transparent border border-solid border-darkest text-darkest px-6 py-[15px] hover:bg-none  hover:text-darkest hover:border-grey-6',
  transparentBlueBorder:
    'bg-transparent border border-solid border-titan-blue-3 text-titan-blue-3 px-6 py-[15px] hover:bg-none hover:bg-titan-blue-4',
  transparentWhiteTextNoBorder:
    'bg-transparent text-white hover:bg-none hover:text-white hover:border-none',
  transparentBlackTextNoBorder:
    'bg-transparent text-darkest hover:bg-none hover:text-dark hover:border-none',
  outlined: 'border',
  base: 'border-none items-center !rounded-none inline-flex cursor-pointer justify-center font-bold font-heading transition relative overflow-hidden focus:outline-none disabled:!text-grey-6 disabled:bg-grey-3 disabled:border-grey-3 disabled:shadow-none disabled:cursor-not-allowed',
  anchor: 'hover:!no-underline',
};

const baseStyles = css`
  font-size: ${(props) => (props.size === 'big' ? '20px' : '16px')};
  line-height: ${(props) => (props.size === 'big' ? '32px' : '16px')};

  padding: ${({ padding }) => (padding != '' ? padding : '16px 24px')};
  height: ${(props) => (props.height != '' ? props.height : 'auto')};
  width: ${({ styles, width }) => styles?.widthDesktop || styles?.widthMobile || width || 'auto'};

  order: ${({ $orderDesktop }) => $orderDesktop};
  margin: ${({ margin, $marginMobile, $marginDesktop }) =>
    margin || $marginDesktop || $marginMobile};

  @media (max-width: 575px) {
    margin: ${({ margin, $marginMobile }) => margin || $marginMobile};
    width: ${({ styles }) => styles?.widthMobile || 'auto'};
    order: ${({ $orderMobile }) => $orderMobile};
  }
  ${(props) => props.$customCss}
  position: relative;
  overflow: hidden;
`;

const StyledLink = styled(HyperLink)`
  ${baseStyles}
`;

const StyledButton = styled.button`
  ${baseStyles}
`;

const StyledButtonWithoutRouting = styled.div`
  ${baseStyles}
`;

const StyledLinkWithoutRouting = styled.div`
  ${baseStyles}
`;

export {
  StyledButton,
  StyledLink,
  StyledButtonWithoutRouting,
  StyledLinkWithoutRouting,
  btnStyles,
};
