import React from 'react';
import PropTypes from 'prop-types';
import useIsClient from 'hooks/useIsClient';
import logoPro from './static/ServicePro.png';
import { STIcon } from '../Icons/STIcon';

export const STLogo = ({ type, variation = 'white', ...otherProps }) => {
  const isClient = useIsClient();
  return isClient ? (
    <>
      {type === 'ServicePro' ? (
        <img src={logoPro} alt="ServicePro Logo" {...otherProps} />
      ) : type === 'Commercial' ? (
        <STIcon
          type={variation === 'white' ? 'st-logo-commercial' : 'st-logo-tertiary-commercial'}
          {...otherProps}
          className={`${otherProps.className} commercial`}
        />
      ) : (
        <STIcon type={variation === 'white' ? 'st-logo' : 'st-logo-tertiary'} {...otherProps} />
      )}
    </>
  ) : (
    <STIcon
      type={variation === 'white' ? 'st-logo' : 'st-logo-tertiary'}
      style={{ filter: 'blur(4px)' }}
      {...otherProps}
    />
  );
};

STLogo.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  width: PropTypes.string, //"210"
  height: PropTypes.string,
};
