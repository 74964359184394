import styled from 'styled-components';
import TextField from '@mui/material/TextField';

export const MUITextField = styled(TextField)`
  &.MuiTextField-root {
    width: 100%;
  }
  .text-field-label {
    top: 2px;
    @media (max-width: 767px) {
      top: -2px;
    }
  }
  .MuiFormLabel-root {
    font-weight: var(--font-weight-500);
    text-overflow: clip !important;
  }

  .MuiFilledInput-root {
    font-weight: var(--font-weight-500);
    text-align: start;
    border: 1px solid #d9d9d9;
    border-radius: 0;
    background-color: var(--white);

    @media (min-width: 768px) {
      &:hover {
        border: 1px solid var(--titan-blue-3);
        background-color: var(--white);
      }
    }

    @media (max-width: 767px) {
      &:hover {
        background-color: var(--white);
      }
      &:focus-within {
        border: 1px solid var(--titan-blue-3);
        background-color: var(--white);
      }
    }

    &.Mui-error {
      border: 1px solid var(--critical-3);
    }
    &.Mui-focused {
      background-color: var(--white);
    }
    .MuiSelect-select:focus {
      background-color: var(--white) !important;
    }

    &:has(.MuiSelect-select) {
      font-weight: var(--font-weight-500);
      pointer-events: none;
      .MuiSelect-select,
      .MuiInputAdornment-root {
        pointer-events: all;
      }
    }
    input {
      height: 23px;
    }
  }
  .MuiSelect-icon {
    margin-right: 6px;
  }
`;
