import React, { forwardRef } from 'react';
import Grid from '@mui/material/Unstable_Grid2';

import { StyledRow } from './styles';

export const Row = forwardRef(
  ({ align, children, className, gutter, onClick, id, justify, ...otherProps }, ref) => {
    let muiColumnSpacing;
    let muiRowSpacing;

    const getGutterPixels = (gutter) => `${gutter?.toString()}px`;

    const getMultiBreakpointGutter = (gutter) => {
      Object.keys(gutter).forEach((key) => (gutter[key] = getGutterPixels(gutter[key])));

      return gutter;
    };

    if (gutter) {
      if (gutter.constructor.name === 'Object') {
        muiColumnSpacing = getMultiBreakpointGutter(gutter);
      } else if (gutter.constructor.name === 'Array') {
        muiColumnSpacing =
          gutter[0].constructor.name === 'Object'
            ? getMultiBreakpointGutter(gutter[0])
            : getGutterPixels(gutter[0]);
        muiRowSpacing =
          gutter?.[1].constructor.name === 'Object'
            ? getMultiBreakpointGutter(gutter[1])
            : getGutterPixels(gutter[1]);
      } else {
        muiColumnSpacing = getGutterPixels(gutter);
      }
    }

    return (
      <StyledRow
        ref={ref}
        container
        alignItems={align}
        className={className}
        columnSpacing={muiColumnSpacing}
        id={id}
        justifyContent={justify}
        onClick={onClick}
        rowSpacing={muiRowSpacing}
        {...otherProps}
      >
        {children}
      </StyledRow>
    );
  },
);

export const Col = forwardRef(
  (
    {
      xs,
      sm,
      md,
      lg,
      xl,
      xxl,
      xxxl,
      children,
      className,
      id,
      justify,
      offset,
      xsOffset,
      smOffset,
      mdOffset,
      lgOffset,
      xlOffset,
      xxlOffset,
      onClick,
      style,
      ...otherProps
    },
    ref,
  ) => {
    return (
      <Grid
        {...(offset && { xsOffset: offset / 2 })}
        {...(xs && { xs: xs / 2 })}
        {...(sm && { sm: sm / 2 })}
        {...(md && { md: md / 2 })}
        {...(lg && { lg: lg / 2 })}
        {...(xl && { xl: xl / 2 })}
        {...(xxl && { xxl: xxl / 2 })}
        {...(xsOffset && { xsOffset: xsOffset / 2 })}
        {...(smOffset && { smOffset: smOffset / 2 })}
        {...(mdOffset && { mdOffset: mdOffset / 2 })}
        {...(mdOffset && { mdOffset: mdOffset / 2 })}
        {...(lgOffset && { lgOffset: lgOffset / 2 })}
        {...(xlOffset && { xlOffset: xlOffset / 2 })}
        {...(xxlOffset && { xxlOffset: xxlOffset / 2 })}
        className={`MuiGrid-item ${className}`}
        id={id}
        justifyContent={justify}
        onClick={onClick}
        ref={ref}
        {...otherProps}
      >
        {children}
      </Grid>
    );
  },
);
