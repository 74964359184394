import React, { useState, useEffect } from 'react';
import ModalMarketingForm from 'components/Form/ModalMarketingForm';
import useDefaultDemoForm from 'hooks/useDefaultDemoForm';

const GlobalModalForm = () => {
  const [isModalOpen, toggleIsModalOpen] = useState(false);
  const [modalFormData, setModalFormData] = useState({});

  const form = useDefaultDemoForm();
  const termsText = form?.termsText;

  const [isHidden, setIsHidden] = useState(true);

  const handleModalClose = () => toggleIsModalOpen(false);

  useEffect(() => {
    window.toggleModal = (marketoFormId = 3781, formTitle, redirectUrl, buttonLabel) => {
      setIsHidden(false);
      setModalFormData({
        termsText,
        marketoForm: {
          id: marketoFormId,
        },
        formTitle,
        redirectUrl,
        buttonLabel,
      });
      toggleIsModalOpen(true);
    };

    return () => {
      window.toggleModal = null;
    };
  }, []);

  return isHidden ? null : (
    <ModalMarketingForm
      onModalCancel={handleModalClose}
      onFormSuccess={handleModalClose}
      isModalOpen={isModalOpen}
      inlineMarketingForm={modalFormData}
      destroyOnClose
    />
  );
};

export default GlobalModalForm;
