import styled from 'styled-components';
import Grid from '@mui/material/Unstable_Grid2';

const StyledRow = styled(Grid)`
  @media (max-width: 575px) {
    max-width: ${({ columnSpacing }) =>
      columnSpacing ? `calc(100% + ${columnSpacing})` : '100vw'};
  }
`;

export { StyledRow };
