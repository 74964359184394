import styled from 'styled-components';
import { FormTitleStyles } from '../styles';

const FormContainer = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  padding: ${(props) => props.containerPadding || '33px 30px'};
  background-color: ${(props) => props.backgroundColor};
  box-shadow: ${(props) => (props.shadow ? 'rgba(160,161,188,.35)' : 'none')};

  &.options-open {
    position: relative !important;
    .slick-slider,
    .slick-track,
    .slick-slide,
    .slick-list {
      height: 100% !important;
      transform: none !important;
      position: static !important;
    }

    .data-layer-form {
      position: static;
    }
  }
  .industryTestVerticals.form-field {
    display: none;
  }
  .signup-form--form {
    .form-field {
      margin-bottom: 16px;
      max-width: calc(100% - 1px);
    }

    .signup-form--hp123-item {
      position: relative;
      overflow: hidden;
      margin: 0;
      width: 0;
      & > div {
        position: absolute;
        top: -2000px;
      }
    }
  }

  .signup-form--footer,
  .signup-form--footer-1 {
    font-size: var(--font-size-16);
    font-weight: var(--font-weight-300);
    line-height: 26px;
    color: grey;
    & > a {
      color: grey;
      text-decoration: underline;
    }
    ${(props) =>
      props.backgroundColor !== '#ffffff' &&
      props.backgroundColor !== 'var(--white)' &&
      ` 
        color:black;
        & > p > a, & > a {
          color: var(--white) !important;
          &:hover {
            text-decoration-color: var(--white) !important;
          }
        }
    `}
  }

  .radio-field--title {
    color: var(--grey-8);
  }
  .radio-field-wrapper {
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 10px;
  }
  .radio-div {
    color: var(--grey-8);
    background-color: transparent;
    float: none;

    &:last-child label,
    label {
      border: 1px solid var(--grey-4);
    }
    &.selected label,
    input:checked + label,
    &.selected:last-child label {
      border: 2px solid var(--titan-blue-3);
    }
  }
  .text-field-wrapper .text-field-input {
    font-size: ${(props) => props.fontSize || '20px'} !important;
  }
  .text-field-wrapper .text-field-input {
    color: rgba(0, 0, 0, 0.85) !important;
  }
  .signup-form--header {
    padding: 0;
    margin: 0 auto;
    width: 100%;

    > div:first-of-type {
      display: flex;
      align-items: center;
    }
  }
  .signup-form--header--inner-container {
    padding: 0;
  }
  .slick-prev {
    z-index: 1;
    display: block;
    position: absolute;
    top: 15px;
    left: 5px;
    padding: 0px;
    &:before {
      border: solid black;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 9px;
      content: '';
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
      @media (max-width: 767px) {
        padding: 7px;
      }
    }
  }
  .slick-slide {
    visibility: hidden !important;
  }
  .slick-active {
    visibility: visible !important;
  }
  @media (max-width: 767px) {
    padding: 24px 16px;

    .signup-form--title {
      font-family: var(--font-family-heading);
      font-size: var(--font-size-mobile-h5);
      letter-spacing: var(--letter-spacing--0-05);
    }

    .signup-form--form {
      .form-field {
        button {
          margin-top: 0;
        }
      }
    }

    .text-field-wrapper .text-field-input {
      font-size: var(--font-size-16) !important;
    }
  }

  @media (max-width: 575px) {
    padding: 20px 25px;

    &.hero-subscribe-form {
      height: auto;
    }

    .signup-form--form {
      .signup-form--submit-button {
        padding: 8px 24px;
        font-size: var(--font-size-16);
        width: 100%;
      }
    }
  }
`;

const FormTitle = styled.p`
  ${FormTitleStyles}
`;

const FormStep = styled.div`
  margin-top: 10px;
`;

export { FormContainer, FormTitle, FormStep };
